<template>
    <div class='screen-page'>
        <div class='upload-header'>
              <span>不会操作？点击查看</span>
              <span class="header-text" @click="showMadel">操作指引<i></i></span>
        </div>
        <div class='screen-main'>
            <div class="imgBox" v-for="(item, index) in imgData" :key="index">
                <img :src="item" alt="" />
                <span @click="dealImages(index)"><img src="./img/delete.png" alt="" /></span>
            </div>
            <div class="upload-img">
                <input ref="fileUploads" id="fileUpload"  type="file"   accept="video/*" @change="changbtn($event)">   
            </div>
        </div>
        <div class='upload-btns' @click="goHomes">提交</div>
        <!-- 芝麻分，社保，公积金录屏 -->
        <Modal
            :show="showscreen"
            @cancel="bankCancel"
            :showCancel="true"
            class="upload-modal"
            :title="modalTitle"
          >
            <div class='upload-mians' v-if="uploadType=='zhimatongyongfen'">
                <img class="ios-img" v-if='statusType==true' src='./img/ios-screen.png' alt=""/>
                <img class="android-img" v-else src='./img/android-screen.png' alt=""/>  
            </div>
            <div class='upload-mians' v-if="uploadType=='shebaozhengming'">
                <img class="ios-img-sb" v-if='statusType==true' src='./img/sb-ios-screen.png' alt=""/>
                <img class="android-img-sb" v-else src='./img/sb-android-screen.png' alt=""/>  
            </div>
            <div class='upload-mians' v-if="uploadType=='gongjijinzhengming'">
                <img class="ios-img-gjj" v-if='statusType==true' src='./img/gjj-ios.png' alt=""/>
                <img class="android-img-gjj" v-else src='./img/gjj-ios.png' alt=""/>  
            </div>
            <div class='upload-btn' @click="shutKnow">我知道了</div>
        </Modal>
    </div>
</template>
<script>
import { toRefs, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import APP from "@/utils/App";
import axios from "axios";
import API from "../../server/api";
import { dealImage } from "../../utils/index";
import Modal from '@/components/Modal/Modal.vue'
import App from '../../utils/App';
export default {
   
  components: {
        Modal
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      fileList: [],
      fileName: "",
      fileType: "",
      num: 0,
      uploadName: "",
      uploadType: "",
      creditNo: "",
      userId: "",
      imgUrl: "",
      imgList: [],
      imgData: [], // 视频路径
      modalTitle:'如何操作录屏',
      showback: false,//弹窗
      showscreen:false,
      statusType:true,//判断机型
      modalType:'',//判断是社保，公积金，芝麻分
      imgNewData:[]
    });
   
    const changbtn=async(e)=>{
      //视频名
      const fileNames=e.target.files[0].name
      const newFileName=fileNames.split('.')[1]
      //视频流
      const fielBuffer=e.target.files[0]
      //视频大小
      const fileSizes=e.target.files[0].size
      const newSize=fileSizes/1000000+'MB'
      
      console.log(fileNames)
      const res=await API.uploadOs({
        fileName:newFileName,
        fileType:'LRD-vodie' ,//文件夹名
      })
      if (res.code == 200) {
          const {host, accessId, dir, policy, signature}=res.result
          const data = new FormData();
          data.append("key", dir);
          data.append("policy", policy);
          data.append("OSSAccessKeyId", accessId);
          data.append("success_action_status", "200");
          data.append("Signature", signature);
          data.append("file",fielBuffer);
          const baseUrl = host;
          Toast.loading({
            message: "加载中...",
            forbidClick: true,
          });
          axios({
            url: baseUrl,
            method: "post",
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res) => {
            Toast.loading({
              message: "上传中...",
              forbidClick: true,
            });
              if (res.status == 200) {
                const imgUrl = `${baseUrl}${dir}`;
                videoCutOutImg(imgUrl)
                state.imgData.push(imgUrl)
                
              }
            })
            .catch((e) => {
              Toast.fail("图片上传失败，请刷新页面重试");
            });
        }
    }
    const showMadel=()=>{
      console.log(776)
      state.showscreen=true
    }
    const bankCancel=()=>{
      state.showscreen=false
    }
    const shutKnow=()=>{
      state.showscreen=false
    }
    const dealImages=(index)=>{
      const {imgData}=state
      imgData.splice(index, 1) 
    }
    // 获取图片列表
    const getPhotoList = async () => {
      const photos = await API.getPhotoList({
        userId: state.userId,
        type: state.uploadType,
      });
      // console.log(photos.echoList, "图片");
      state.imgData = photos.echoList;
      // if(photos.echoList.length>0){
      //   state.imgNewData=photos.echoList;
      //   photos.echoList.map((item,index)=>{
         
      //     videoCutOutImg(item)
      //     //  console.log(newARR,'09y6t6t')
      //   })
        
      // }
     
    };
    const goHomes = () => {
      const imgModel = {};
      imgModel.imgData = state.imgData;
      imgModel.type = state.uploadType;
      if (state.imgData.length === 0) {
        Toast.fail("请先上传附件!");
        return false;
      } else {
        uploadFile({
          creditNo: state.creditNo,
          imgModel: imgModel,
          userId: state.userId,
        });
      }
    };
     // 上传图片
    const uploadFile = async (data) => {
      const imgFile = await API.uploadFile(data);
      if (imgFile) {
        Toast.success("上传成功");
          window.location.reload()
          window.location.href='meilly://app.meilly.cn'
          
      }
    };
    const  videoCutOutImg =(url, frames = 5)=> {
      let video = document.createElement('video')
      let slide = 0.5
      video.crossOrigin = 'anonymous'
      video.setAttribute('src', url)
      video.currentTime = frames // 帧数，取5帧吧，第一帧可能会是黑色，帧数自定义取
      video.setAttribute('width', 200)
      video.setAttribute('height', 200)
      return new Promise((resolve, reject) => {
        video.addEventListener('loadeddata', function () {
            let canvas = document.createElement('canvas')
            const width = video.width // canvas的尺寸和图片一样
            const height = video.height
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d').drawImage(video, 0, 0, width,height)
            const imgApp=canvas.toDataURL('image/png')
            state.imgNewData.push(imgApp)
            // resolve(canvas.toDataURL('image/png'))
            
        })
      })
    }
    // 页面挂载完成
    onMounted(() => {
      //  console.log(childRef,'dom');
      // changbtn()
      state.statusType=APP.BROWSER.android
      const routeInfo = route.query.jsParam;
      const arr = routeInfo.split("&");
      const obj = {};
      for (var i = 0; i < arr.length; i++) {
        const aa = arr[i].split("=");
        obj[aa[0]] = aa[1];
      }
      state.routeInfo = obj;
      console.log(state.routeInfo, "route444");
      state.uploadName = state.routeInfo.everyName;
      state.uploadType = state.routeInfo.type;
      state.userId=state.routeInfo.useIDs
   
      // state.imgList = $store.state.imgList;
      const {dataType,datalength}=state.routeInfo
      if(dataType=='lupin'){
        // state.showback = false
        if(datalength>0){
           state.showscreen=false
        }else{
          state.showscreen=true
        }
        
      }
      if(state.uploadType=='shebaozhengming'){
        state.modalTitle="如何操作社保录屏"
      }else if(state.uploadType=='gongjijinzhengming'){
        state.modalTitle="如何操作公积金录屏"
      }
      APP.SET_TITLE("上传录屏");
      getPhotoList();
    })
    return {
      ...toRefs(state),
      changbtn,
      showMadel,
      bankCancel,
      shutKnow,
      goHomes,
      dealImages
    };
  },
};
</script>
<style lang="less" src="./index.less" scoped></style>